// Think of this as the entry point into the framework
import {useState} from 'react'
import type {EmbeddedPartialData} from './embedded-data-types'
import {BaseProviders} from './BaseProviders'
import type {BrowserHistory} from '@remix-run/router'
import {createMemoryHistory} from '@remix-run/router'
import {AppContextProvider} from './AppContextProvider'
import {PartialRouter} from './PartialRouter'
import {CommonElements} from './CommonElements'
import {createBrowserHistory} from './create-browser-history'
import type {ReactPartialAnchorElement} from '@github-ui/react-partial-anchor-element'
import {usePartialAnchorProps} from './react-partial-anchor'
import {ErrorBoundary} from './ErrorBoundary'
import type {ErrorContext} from '@github-ui/failbot'

interface Props {
  partialName: string
  embeddedData: EmbeddedPartialData
  Component: React.ComponentType
  wasServerRendered: boolean
  ssrError?: HTMLScriptElement
  anchorElement?: ReactPartialAnchorElement | null
  onError?: (error: Error, context?: ErrorContext) => void
}

export function PartialEntry({
  partialName,
  embeddedData,
  Component,
  wasServerRendered,
  ssrError,
  anchorElement,
  onError,
}: Props) {
  // Create a ref to track the browser history:
  const [browserHistory] = useState<BrowserHistory>(() => {
    const window = globalThis.window as Window | undefined

    return !window ? createMemoryHistory({initialEntries: [{pathname: '/'}]}) : createBrowserHistory({window})
  })

  const partialAnchorProps = usePartialAnchorProps(anchorElement)

  // Wrap the partial in an AppContextProvider and static Router so that react-core links
  // will be functional.
  return (
    <BaseProviders appName={partialName} wasServerRendered={wasServerRendered}>
      <ErrorBoundary onError={onError}>
        <AppContextProvider history={browserHistory} routes={[]}>
          <PartialRouter history={browserHistory}>
            <Component {...embeddedData.props} {...(partialAnchorProps as object)} />
            <CommonElements ssrError={ssrError} />
          </PartialRouter>
        </AppContextProvider>
      </ErrorBoundary>
    </BaseProviders>
  )
}

try{ PartialEntry.displayName ||= 'PartialEntry' } catch {}